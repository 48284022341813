import { useState, useEffect } from "react";
import { useStoryblokApi } from "@storyblok/react";
import { storyblokEditable } from "@storyblok/react";
import './CocktailList.css';

const CocktailList = ({ blok }) => {
    const [cocktails, setCocktails] = useState([]);
    const storyblokApi = useStoryblokApi();

    useEffect(() => {
        let page = 1;
        let allStories = [];
        const fetchStories = async () => {
            try {
                let total = 0;
                do {
                    const response = await storyblokApi.get('cdn/stories', {
                        version: 'draft',
                        starts_with: 'cocktails',
                        page: page,
                        per_page: 25 // Adjust if you want more per page
                    });
                    allStories = [...allStories, ...response.data.stories];
                    total = response.total;
                    page += 1;
                } while (allStories.length < total);

                setCocktails(allStories);
            } catch (error) {
                console.error("Error fetching stories:", error);
            }
        };

        fetchStories();
    }, [storyblokApi]);

    return (
        <div {...storyblokEditable(blok)} className="cocktail-list">
            <h2 className={"list-title"} style={{ textAlign: "center" }}>Cocktail List</h2>
            <div className="cocktail-grid">
                {cocktails.map((cocktail) => (
                    <div className="cocktail-item" key={cocktail.id}>
                        <a href={`/${cocktail.full_slug}`} className="cocktail-link">
                            <img
                                src={cocktail.content.Image.filename}
                                alt={cocktail.name}
                                className="cocktail-image"
                            />
                            <h3 className="cocktail-title">{cocktail.name}</h3>
                        </a>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default CocktailList;
